import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

const MainWrapper = styled.div`
  padding-top: 50px;
  min-height: 100vh;

  @media (min-width: 768px) {
    display: flex;
    jusdtify-content: center;
    align-items: center;
    padding-top: 0;
  }
`

const MainTitle = styled.h1`
  font-size: 36px;
  margin-bottom: 50px;

  small {
    font-size: 28px;
    display: block;
  }

@media (min-width: 768px) {
  font-size: 60px;
  
  small {
    font-size: 35px;
  }

  @media (min-width: 1200px) {
    font-size: 80px;
    
    small {
      font-size: 45px;
    }
  

}

`

const SubTitle = styled.h2`
  margin-bottom: 5px;
`

const ContentWrapper = styled.div`
  > div {
    padding: 20px 0;
  }
`

const Downloads = styled.div``

const DlButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  a {
    text-decoration: none;
    color: #333;
    background: transparent;
    display: block;
    text-align: center;
    border: 2px solid #3e63a0;
    padding: 10px 25px;
    border-radius: 15px;
    transition: 0.25s all ease-out;
    margin: 5px 0;
    font-size: 16px;
    width: 100%;
  }

  @media (min-width: 768px) {
    a {
      margin: 5px;
      display: inline-block;
      width: auto;
      padding: 6px 25px;
    }
    a:first-child {
      margin-left: 0;
    }
  }

  a:hover {
    background: #3e63a0;
    color: #fff;
  }
`

const Links = styled.div``

const Kontakt = styled.div``

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Startseite"
        description="Hier finden Sie meine Arbeit zum Thema Pilze im Christentum zum Download"
      />
      <MainWrapper>
        <ContentWrapper>
          <MainTitle>
            Pilze im Christentum<small>Bildnachweissammlung</small>
          </MainTitle>
          <Downloads>
            <SubTitle>Downloads</SubTitle>
            <DlButtons>
              <a
                href="https://downloads.pilze-im-christentum.ch/PILZE-IM-CHRISTENTUM-unkomprimiert.pdf"
                target="_blank"
              >
                Pilze im Christentum <small>(PDF)</small>
              </a>

              <a
                href="https://downloads.pilze-im-christentum.ch/PILZE-IM-CHRISTENTUM-komprimiert.pdf"
                target="_blank"
              >
                Pilze im Christentum <small>(PDF, komprimiert)</small>
              </a>

              <a
                href="https://downloads.pilze-im-christentum.ch/PILZE-IM-CHRISTENTUM-Bildarchiv.zip"
                download
              >
                Bildarchiv <small>(Zip)</small>
              </a>
            </DlButtons>
          </Downloads>
          <Links>
            <SubTitle>Website</SubTitle>
            <a href="https://pilze-im-christentum.info/" target="blank">
              www.pilze-im-christentum.info
            </a>
          </Links>

          <Kontakt>
            <SubTitle>Kontakt</SubTitle>
            <p>kontakt(at)pilze-im-christentum.ch</p>
          </Kontakt>
        </ContentWrapper>
      </MainWrapper>
    </Layout>
  )
}
export default IndexPage
